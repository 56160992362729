<template>
  <div class="py-7">
    <v-row
      justify="center"
      align="center"
    >
      <v-col
        class="mb-0 pa-0 relative"
        cols="11"
      >
        <v-form
          ref="form"
          @submit.prevent="addFlashcard"
        >
          <div
            id="title"
            class="d-flex justify-space-between align-center flex-column flex-md-row"
            :class="smAndDown ? 'mb-5': 'mb-8'"
          >
            <div
              class="section__text--title d-flex justify-space-between align-center order-2 order-md-first align-self-start align-self-md-center"
              :class="smAndDown ? 'mt-4': ''"
            >
              {{ $route.name==='CreateMazo' ? 'Nueva mazo:': 'Editar mazo' }}
            </div>
            <div class="search align-self-end" >
              <v-text-field
                id="search"
                v-if="$route.name==='UpdateMazo'"
                v-model="search"
                placeholder="Buscar en el mazo"
                outlined
                solo
                class="rounded-xl search__input"
                dense
                height="40"
                append-icon="mdi-magnify"
                @focus="fichaAutofocus=false"
              />
            </div>
          </div>
          <label class="label ml-4">
            TÍTULO
          </label>
          <v-text-field
            v-model="mazo.title"
            placeholder="Introduce un título para la unidad"
            class="rounded-pill mt-2"
            outlined
            autofocus
            tabindex="1"
            :readonly="isReadOnly"
            :rules="[rules.required, rules.max(50)]"
          />
          <label class="label ml-4">
            DESCRIPCIÓN
          </label>
          <v-text-field
            v-model="mazo.description"
            placeholder="Añade una descripción para la unidad"
            class="rounded-pill mt-2"
            outlined
            tabindex="2"
            :readonly="isReadOnly"
            :rules="[rules.required]"
          />
          <transition-group
            v-if="!loadingMazo"
            name="list-complete"
            class="d-flex"
            :class="$route.name==='UpdateMazo' ? 'mb-15 flex-column-reverse' : 'flex-column'"
            tag="div"
          >
            <FichaCardItem
              v-for="(item, index) in itemList"
              :key="item.id"
              :id="`flashcard-${index}`"
              :item="item"
              :index="index"
              :currentMazo="currentMazo"
              :fichaAutofocus="fichaAutofocus"
              :customtab="setCustomTab(index)"
              :errors="flashcardsErrors[index]"
              class="list-complete-item"
              :class="{
                'my-0': index===items.length-1,
                'mb-11': $route.name!=='UpdateMazo',
                'mt-11': $route.name==='UpdateMazo' && index!==items.length-1
              }"
              @deleteFicha="deleteFicha"
            />
          </transition-group>
          <div v-else class="d-flex justify-center align-center">
            <v-progress-circular indeterminate />
          </div>
          <br v-for="i in 6" :key="i">
          <!-- <v-progress-circular v-else indeterminate /> -->
          <div
            class="d-flex flex-column btn__wrapper"
            :class="$route.name==='UpdateMazo' ? 'mt-5 fixed': 'mx-auto'"
            :style="$route.name==='UpdateMazo' ? '': 'margin-top: -13%;'"
          >
            <v-btn
              class="rounded-pill btn mx-auto"
              :width="smAndDown ? '100%': '406'"
              :disabled="items.length===120"
              :tabindex="items.length*5+4"
              height="50"
              type="submit"
            >
              + Añadir Flashcards
            </v-btn>
            <v-btn
              class="rounded-pill btn mx-auto"
              :class="items.length ? 'btn--createmazo': ''"
              :width="smAndDown ? '100%': '406'"
              height="50"
              :tabindex="items.length*5+5   "
              :loading="loading"
              @click="addFlashcardsInBatch"
            >
              {{ items.length ? $route.name === 'UpdateMazo' ? 'Guardar Cambios': 'Crear mazo': '+ Añadir Flashcards en masa' }}
            </v-btn>
          </div>
        </v-form>
        <SuccessModal
          v-model="successMazoCreated"
          :message="modal.message"
          :acceptMessage="modal.acceptMessage"
          :cancelMessage="modal.cancelMessage"
          @showMazo="$router.push({ name: 'Mazos' })"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { required, maxLength } from '@/utils/rules.js'
import FichaCardItem from '@/components/mazos/FichaCardItem.vue'
import SuccessModal from '@/components/mazos/SuccessModal.vue'
import axios from 'axios'
import { mapState, mapActions } from 'vuex'

export default {
  components: { FichaCardItem, SuccessModal },
  props: {
    currentMazo: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    items: [],
    fichaAutofocus: true,
    add: false,
    limit: 10,
    flashcardsErrors: [],
    mazo: {
      title: '',
      description: ''
    },
    rules: {
      required,
      max: maxLength
    },
    successMazoCreated: false,
    modal: {
      message: '',
      acceptMessage: '',
      cancelMessage: ''
    },
    newsFlashcards: [],
    flashcardsListSearched: [],
    numFicha: 0,
    loadingMazo: false,
    loading: false,
    search: ''
  }),
  computed: {
    ...mapState('myflashcards', ['mazoList']),
    smAndDown () {
      return this.$vuetify.breakpoint.smAndDown
    },
    isReadOnly () {
      return this.$route.name === 'UpdateMazo' && this.mazo.title === 'Mazo de errores'
    },
    flashcards () {
      if (this.$route.name === 'UpdateMazo') {
        return this.items.map(item => ({
          id: item.id,
          enunciado: item.enunciado,
          respuesta: item.respuesta,
          comentario: item.comentario
        }))
      } else {
        return this.items.map(item => ({
          enunciado: item.enunciado,
          respuesta: item.respuesta,
          comentario: item.comentario
        }))
      }
    },
    itemList () {
      if (this.search !== '') {
        return this.flashcardsListSearched
      } else {
        return this.items
      }
    }
  },
  watch: {
    async search () {
      if (this.search !== '') {
        const response = await axios.get(`api/flashcards/mis_mazos/${this.$route.params.id}/flashcards/?search=${this.search}`)
        this.flashcardsListSearched = response.data.results
      } else {
        this.fichaAutofocus = true
      }
    },
    items () {
      // to validate route leave
      this.$emit('setItems', {
        titulo: this.mazo.title,
        descripcion: this.mazo.description,
        flashcards: this.flashcards
      })
    },
    mazo: {
      handler (to, from) {
        // to validate route leave
        this.$emit('setItems', {
          titulo: this.mazo.title,
          descripcion: this.mazo.description,
          flashcards: this.flashcards
        })
      },
      deep: true
    },
    newsFlashcards () {
      this.$emit('setNewsFlashcards', this.newsFlashcards)
    }
  },
  beforeUpdate () {
    this.edited = true
  },
  methods: {
    setCustomTab (index) {
      return (index + 1) * 5 - 2
    },
    async addFlashcardsInBatch () {
      if (this.items.length) {
        if (this.$refs.form.validate()) {
          this.loading = true
          const payload = {
            titulo: this.mazo.title,
            descripcion: this.mazo.description,
            flashcards: this.flashcards
          }
          if (this.$route.name === 'CreateMazo') {
            try {
              await axios.post('api/flashcards/mis_mazos/', payload)
              this.$emit('setCreated')
              this.successMazoCreated = true
              this.modal = {
                message: `Felicidades! se creó con éxito la Unidad de estudio (${payload.titulo}).`,
                acceptMessage: 'Ver mazo',
                cancelMessage: ''
              }
            } catch (e) {
              const errors = e.response.data.flashcards
              this.flashcardsErrors = errors
              const indexList = []
              let i = 0
              for (const item of errors) {
                if (Object.keys(item).length !== 0) {
                  indexList.push(i)
                }
                i++
              }
              document.getElementById(`flashcard-${indexList[indexList.length - 1]}`).scrollIntoView({ behavior: 'smooth' })
            }
          } else {
            try {
              await axios.patch(`api/flashcards/mis_mazos/${this.$route.params.id}/`, payload)
              this.newsFlashcards = []
              this.modal = {
                message: `Felicidades! se actualizó con éxito la Unidad de estudio (${payload.titulo}).`,
                acceptMessage: 'Ver mazo',
                cancelMessage: ''
              }
              this.successMazoCreated = true
            } catch (e) {
              if (e.response.data.flashcards) {
                const errors = e.response.data.flashcards
                this.flashcardsErrors = errors
                const indexList = []
                let i = 0
                for (const item of errors) {
                  if (Object.keys(item).length !== 0) {
                    indexList.push(i)
                  }
                  i++
                }
                document.getElementById(`flashcard-${indexList[indexList.length - 1]}`).scrollIntoView({ behavior: 'smooth' })
              }
            }
          }
          this.loading = false
        } else {
          window.scrollTo(0, 0)
        }
      } else {
        for (let i = 0; i < this.limit; i++) {
          this.items.push({
            id: i,
            enunciado: '',
            respuesta: '',
            comentario: ''
          })
        }
      }
    },
    async deleteFicha (index) {
      const item = this.items[index]
      if (item.enunciado !== '' || item.respuesta !== '' || item.comentario !== '') {
        const confirm = await this.$root.$confirm({
          title: 'Aviso',
          message: 'Está seguro que desea eliminar el flashcard?',
          acceptText: 'Si',
          cancelText: 'No'
        })
        if (confirm) {
          this.items.splice(index, 1)
        }
      } else {
        this.items.splice(index, 1)
      }
    },
    async addFlashcard () {
      // if (this.$refs.form.validate()) {
      //   console.log('validated')
      // }
      this.newsFlashcards.push({
        id: this.numFicha,
        enunciado: '',
        respuesta: '',
        comentario: ''
      })
      this.items.push({
        id: this.numFicha,
        enunciado: '',
        respuesta: '',
        comentario: ''
      })
      this.numFicha++
    },
    ...mapActions('myflashcards', ['fetchMazoList', 'fetchFlashcardsByMazo'])
  },
  async mounted () {
    if (this.$route.name === 'UpdateMazo') {
      this.loadingMazo = true
      // get flashcards that belong to current mazo
      await this.fetchFlashcardsByMazo({ id: this.$route.params.id, search: '' })
      if (this.currentMazo.results) {
        this.numFicha = this.currentMazo.results.map(item => item.id).reduce((p, c) => p + c, 0) + 1
        this.items = [...this.currentMazo.results]
      }
      // get current mazo to obtain title and description
      const mazoId = parseInt(this.$route.params.id)
      await this.fetchMazoList()
      const mazo = await this.mazoList.find(item => item.id === mazoId)
      this.mazo = {
        title: mazo.titulo,
        description: mazo.descripcion
      }
      this.loadingMazo = false
    }
    await document.getElementById('title').scrollIntoView()
  }
}
</script>

<style lang="scss" scoped>
.label {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #262626;
}
.btn {
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #999999;
  text-transform: none;
  margin-bottom: 30px;
  background: #FFFFFF !important;
  &__wrapper {
    bottom: 10px;
    left: 50%;
    right: 50%;
    margin-right: -349px;
  }
  &:hover {
    color: #0C8CD9;
  }
  &--createmazo {
    background: linear-gradient(270deg, #0480DA 0%, #1FC1D0 100%) !important;
    border-radius: 40px;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: #FFFFFF;
      &:hover {
        color: #FFFFFF;
      }
  }
}
.search {
  width: 375px;
  &__input {
    background: #FFFFFF;
    border-radius: 15px;
    height: 40px;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #959595;
  }
}
@media (max-width: 960px) {
  .search {
    width: 85%;
  }
  .btn {
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 25px;
    &__wrapper {
      margin: 0 15px;
      left: 0;
      right: 0;
    }
  }
}
.list-complete-item {
  transition: all .5s;
  display: inline-block;
  margin-right: 10px;
}
.list-complete-enter, .list-complete-leave-to
/* .list-complete-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(30px);
}
.list-complete-leave-active {
  position: absolute;
}
</style>
